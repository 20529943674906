//import * as React from 'react';
import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {atom, useRecoilState} from 'recoil';
import { withRouter, useHistory, useLocation} from "react-router-dom";
import Button from '@mui/material/Button';
import secureLocalStorage from 'react-secure-storage';
import { useRecoilValue } from 'recoil';
import { userID, selectedExperimentState, seletedPatientIdState, isCurrentBloodSugarAtom, searchValueState,seletedPatientState } from "../../../states/recoilState";



export default function PatientTable(props) {
  const location = useLocation();
  const history = useHistory();
  
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedRow, setSelectedRow] = useState(null);
  const [originTableRows, setOriginTableRows] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [searchValue, setSearchValue] = useRecoilState(searchValueState);
  const [selectedExperimentValue, setSelectedExperiment] = useRecoilState(selectedExperimentState);

  const [selectedPatientId, setSeletedPatientId] = useRecoilState(seletedPatientState);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function onRowSelected(index, row){
    setSelectedExperiment(row);
    setSelectedRow(index);
    setSeletedPatientId(row.patient_id);
    
    secureLocalStorage.setItem('selectedExperimentValue',JSON.stringify(row));
    secureLocalStorage.setItem('selectedRow', index);
    secureLocalStorage.setItem('selectedPatientId',row.patient_id);
  }

  const columns = [
    { id: 'name', label: '이름', minWidth: 170 },
    { id: 'code', label: '식별 번호', minWidth: 100, align:'center' },
    { id: 'createdDate', label: '마지막 데이터', minWidth: 170, align: 'right' },
  ];


  //useEffect들
  useEffect(()=>{
    setOriginTableRows(props.tableData);
    setTableRows(props.tableData);
  },[props.tableData]);

  useEffect(()=>{
    let tempList = originTableRows.filter(obj => obj.name.includes(searchValue) || obj.code.includes(searchValue) || obj.createdDate.includes(searchValue));
    setTableRows([...tempList]);
  },[searchValue]);

  //로그인 후 첫 화면에서는 row가 선택되지 않은 모습으로
  useEffect(() => {
    if(props.isAfterLogin === true){
      setSelectedRow(null);
    }
  }, [props.isAfterLogin]);

  //화면 렌더링
  useEffect(() => {
    //새로고침과 history.push를 구분하기
    //새로고침이라면
    if(history.action === 'POP'){
      setSelectedExperiment(null);
      setSelectedRow(null);
    }
    //history.push로 화면전환이 이뤄졌다면
    else if(history.action === 'PUSH'){
      setSelectedExperiment(JSON.parse(secureLocalStorage.getItem('selectedExperimentValue')));

      setSelectedRow(parseInt(secureLocalStorage.getItem('selectedRow')));
      setSeletedPatientId(secureLocalStorage.getItem('selectedPatientId'));
    }
    
  },[]);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', border: '1px solid #8A8A8A'}}>
      <TableContainer style={{maxHeight:'800px', height:'70vh'}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow >
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight:'bold', fontSize:'16px'}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow 
                    hover 
                    role="checkbox" 
                    tabIndex={-1} 
                    key={row.patient_id} 
                    selected={selectedRow === row.patient_id}
                    onClick={() => onRowSelected(row.patient_id, row)}>

                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} style={{fontSize:'16px'}}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="페이지 당 행 수"
        rowsPerPageOptions={[10, 20]}
        component="div"
        count={tableRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

import React, { useCallback, useEffect, useState, useRef, useLayoutEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import BackImg from "../../assets/uxnicon/9.png";
import TitleImg from "../../assets/uxnicon/8.png";
import "./login.scss";
import {
  loginGet,
  loginPost,
  agmsClientLoginPost,
  agmsClientRequestGet,
  requestGet,
  requestPut,
  agmsClientConfirmGet,
  agmsClientRequestPost
} from "../../apis/request";
import jwt_decode from "jwt-decode";
import FindIdModal from "./FindIdModal";
import TermAgreement from "./TermAgreement";
import ChangePWModal from "./ChangePWModal";
import NoPw from "../../assets/uxnicon/nopw.png";
import OkPw from "../../assets/uxnicon/okpw.png";
import moment from "moment";
import { useRecoilValue, useSetRecoilState, useRecoilState} from "recoil";
import { termAllAgree, annotationChartYAxisAtom } from "../../states/recoilState";

import UXNBackgroundImage from "./imgs/login_background_img_small_small.png";
import GoogleFontImage from "./imgs/google_logo.png";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';

import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import secureLocalStorage from "react-secure-storage";

import { FacebookLoginButton } from "react-social-login-buttons";


const Login = (props) => {
  const [name, setName] = useState("");
  const [loginId, setLoginId] = useState("");
  const [email, setEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [password, setPassword] = useState("");
  const [userCall, setUserCall] = useState("");
  const [birthday, setBirthday] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [hosAddress, setHosAddress] = useState("");
  const [hosCall, setHosCall] = useState("");
  const [hosName, setHosName] = useState("");
  const [hospitalId, setHospitalId] = useState(null);
  const [acceptCode, setAcceptCode] = useState("");
  const [isPassword, setIsPassword] = useState(false);
  const [isPasswordSignUp, setIsPasswordSignUp] = useState(false);
  const [acceptCodeOk, setAcceptCodeOk] = useState(false);
  const [gender, setGender] = useState("MALE");
  const [logInUp, setLogInUp] = useState(false);
  const [findId, setFindId] = useState(false);
  const [changePW, setChangePW] = useState(false);
  const [rememberID, setRememberID] = useState(false);
  const [autoLoginCheck, setAutoLoginCheck] = useState(false);
  const history = useHistory();
  const localEmail = secureLocalStorage.getItem("localEmail");
  const autoLogin = secureLocalStorage.getItem("autoLogin");
  const [termAgreement, setTermAgreement] = useState(false);

  const [hospitalList, setHospitalList] = useState([
    {
      hospital_id: 0,
      hospital_name: ''
    }
  ]);

  const [annotationChartYAxis, setAnnotationChartYAxis] = useRecoilState(annotationChartYAxisAtom);

  const termAllAgreeInfo = useRecoilValue(termAllAgree);
  const setTermAllAgreeInfo = useSetRecoilState(termAllAgree);

  /**
  *
  * @date     2023.02.03
  * @brief    회원가입 버튼 클릭 이벤트 함수
  * @details  이메일 정보로 회원 가입 후 인증 메일 발송 - 메일 인증 후 가입 완료
  * @param   
  * @return   
  *
  */
  //let result = await agmsClientRequestPost(`/api/staff/join` , secureLocalStorage.getItem("refreshtoken"));
  const handleRememberIdClick = () => {  
    setRememberID(!rememberID);    
  }

   const clickSignUp = async (e) => {
    e.preventDefault();

    //의료기관 이름, 성명, 전화번호, 이메일, 비밀번호 모두 있는지 체크
    if( 
      // (hosName !== '' || null) 
      //   && 
        (name !== '' || null) 
        && (userCall !== '' || null)
        && (email !== '' || null)
        && (password !== '' || null)){

      if (isPasswordSignUp) {
        if (password === passwordCheck) {
          if(termAllAgreeInfo.agree === "true") {
            try{
              let body = {
                email: email,
                password: password,
                hospital_id: hospitalId,
                name : name,
                phone_number : userCall,
                role : 'STAFF'
              }
              
              let signUpResult = await agmsClientRequestPost(`api/staff/join`, body); 

              const params = new URLSearchParams();
              params.append("email", email);
              let emailSend = await requestPut(`/api/mail/send?${params}`);
              alert("이메일 인증을 위한 메일이 발송되었습니다. 메일함을 확인해 주세요.");

              setLoginId(email);
              setLogInUpState(false);
              setTermAllAgreeInfo({ agree: "false" });
            } catch (error) {
              if (error.response?.status === 413) {
                alert("이미 가입된 이메일입니다.");
              } else {
                alert("회원가입을 할 수 없습니다.");
              }
            }
            // if (name !== "" && email !== "" && password !== "" && gender !== "") {
            //   try {
            //     let body = {
            //       approval_code: acceptCode,
            //       staff_name: name,
            //       email: email,
            //       password: password,
            //       gender: gender,
            //       phone_number: userCall,
            //       birth: birthday,
            //       hospital: {
            //         hospital_address: hosAddress,
            //         hospital_call: hosCall,
            //         hospital_name: hosName,
            //       },
            //     };
            //     const params = new URLSearchParams();
            //     params.append("email", email);
            //     let res = await loginPost(`/api/v1/sign-up/staff/save`, body);
            //     if (res.status !== 500) {
            //       await requestPut(`/api/v1/mail/send?${params}`);
            //       setLoginId(email);
            //       alert("이메일 인증 해주십시요.");
            //       setLogInUpState(false);
            //       setTermAllAgreeInfo({ agree: "false" });
            //     }
            //   } catch (error) {
            //     if (error.response?.status === 413) {
            //       alert("이미 가입된 이메일입니다.");
            //     } else {
            //       alert("회원가입을 할 수 없습니다.");
            //     }
            //   }
            // } else {
            //   alert("회원 정보를 기입해 주세요.");
            // }
          }
          else {
            alert("이용약관 및 개인정보 이용 동의해야 합니다.");
          }

        } else {
          alert("비밀번호 확인이 안맞습니다.");
        }
        
      } else {
        alert("비밀번호 조건이 안맞습니다.");
      }

    }
    else {
      alert("빈 칸을 채워주세요.");
    }
    
  };


  /**
  *
  * @date     2023.02.03
  * @brief    로그인 버튼 클릭 이벤트 함수
  * @details  로그인 시도 및 다중 로그인 체크
  * @param    res1.currentWebLoginCount - 다른곳 로그인 여부 확인(서버에서 받아 옴)
  * @param    res1.loginOtherIp - 먼저 로그인 된 IP 정보
  * @return   
  *
  */
  const onClickGoogleLogin = async () => {
    window.location.href = "https://agmsview.kr:8080/oauth2/authorization/google";
  }
  
  const clickSignIn = async (e) => {
    e.preventDefault();
    try {
      let body = {
        email: loginId,
        password: loginPassword
      }
      //let res = await loginPost(`/api/v1/login`, body);
      
      //로그인 (access와 refresh 토큰을 받아온다.)
      let loginResult = await agmsClientLoginPost(`/api/login`, body);
     
      //만약 자동 로그인이 설정되어 있으면 로그인 정보를 저장한다.
      if(autoLoginCheck){
        let autoLoginObj = {
          email: loginId,
          password: loginPassword
        };
        secureLocalStorage.setItem("autoLogin", JSON.stringify(autoLoginObj));
      }else{
        secureLocalStorage.removeItem("autoLogin");
      }

      // if(loginResult.resultCode == 9999){
      //   alert(loginResult.failMessage);
      //   return;
      // }
      // else if(loginResult.resultCode == 9999){
      //   alert('비밀번호 찾기로 재설정 후 로그인해주세요!');
      //   return;
      // }
      if(loginResult.IsApproval === false) {
        alert('담당 의사의 승인을 받아주세요!');
        return;
      }
      else if(loginResult.resultCode === 409 || loginResult.resultCode === 410){
        alert('인증기간 만료. 비밀번호 찾기로 재설정 후 로그인해주세요!');
        return;
      }
      else{
        //로컬에 토큰 등을 저장
        secureLocalStorage.setItem('hospitalId', loginResult.HospitalId);
        secureLocalStorage.setItem("authUser", loginResult.ID);
        secureLocalStorage.setItem("refreshtoken", loginResult.refreshToken);
        secureLocalStorage.setItem("accesstoken", loginResult.accessToken);
        secureLocalStorage.setItem("role", loginResult.Role);
        secureLocalStorage.setItem("email", loginResult.Email);
        secureLocalStorage.setItem("logoutTrigger", false);
        setAnnotationChartYAxis(
          {
            min: 0,
            max: 30
          },
        );

        //체크 (로그인 시도 계정이 현재 로그인되어 있는치 체크. 다른곳에서 로그인되어 있으면 IP등 정보를 리턴)
        let checkResult = await agmsClientRequestGet(`/api/staff/home/check?device=WEB`);

        //현재 로그인한 개수? 이거에 대한 무언가를 처리해야할까?
        if(checkResult.currentWebLoginCount > 0){
          
          if(checkResult.loginOtherIp === false){

            //컨펌 (DB에 refresh 토큰을 저장한다.)
            let confirmResult = await agmsClientConfirmGet(`/api/staff/home/confirm?device=WEB`);
            secureLocalStorage.setItem("uuid", confirmResult.tokenUuid);
          }
          else{
            if(
              window.confirm(
                "현재 다른 IP에서 로그인이 되어있습니다.\n기존 로그인을 로그아웃 후 새로 로그인을 하시겠습니까?"
              )
            )
            {
              //컨펌 (DB에 refresh 토큰을 저장한다.)
              let confirmResult = await agmsClientConfirmGet(`/api/staff/home/confirm?device=WEB`);
              secureLocalStorage.setItem("uuid", confirmResult.tokenUuid);
            }
            else{
              let result = await agmsClientRequestGet(
                `/api/logout`
              );
              secureLocalStorage.removeItem("refreshtoken");
              secureLocalStorage.removeItem("accesstoken");
              secureLocalStorage.removeItem('authUser');
              
              history.push('/');
            }
          }
        }else{
          //컨펌 (DB에 refresh 토큰을 저장한다.)
          let confirmResult = await agmsClientConfirmGet(`/api/staff/home/confirm?device=WEB`);
          secureLocalStorage.setItem("uuid", confirmResult.tokenUuid);
        }

        history.push("/patient/list", {
          isAfterLogin: true,
        });
      }
      
    } 
    catch (error) {
      if (error.response?.status === 416) {
        alert("비밀번호 찾기를 시도해주세요.");
      } else if (error.response?.status === 412) {
        alert("메일 인증시간이 초과 되었습니다. 다시 회원가입을 해주세요.");
      } else if (error.response?.status === 410) {
        alert("회원 인증 기간이 만료되었습니다.\n계정 메일로 보낸 재 인증 링크를 클릭하여 기간 연장 후 사용해 주세요.");
      } else if (error.response?.status === 401) {
        alert("로그인 정보를 확인해주세요.");
      } else {
        alert("로그인을 할 수 없습니다.");
        console.log(error);
      }
    }
  };

   //function : 자동 로그인 버튼 클릭 이벤트 함수
  const clickAutoSignIn = async () => {
    try {
      let body = JSON.parse(autoLogin);
      //let res = await loginPost(`/api/v1/login`, body);
      
      //로그인 (access와 refresh 토큰을 받아온다.)
      let loginResult = await agmsClientLoginPost(`/api/login`, body);

      //만약 자동 로그인이 설정되어 있으면 로그인 정보를 저장한다.
     
      let autoLoginObj = body;
      secureLocalStorage.setItem("autoLogin", JSON.stringify(autoLoginObj));
      
      if(loginResult.resultCode == 9999){
        alert(loginResult.failMessage);
        return;
      }
      else if(loginResult.resultCode == 9999){
        alert('비밀번호 찾기로 재설정 후 로그인해주세요!');
        return;
      }
      if(loginResult.IsApproval === false){
        alert('담당 의사의 승인을 받아주세요!');
        return;
      }
      else{
        //로컬에 토큰 등을 저장
        secureLocalStorage.setItem('hospitalId', loginResult.HospitalId);
        secureLocalStorage.setItem("authUser", loginResult.ID);
        secureLocalStorage.setItem("refreshtoken", loginResult.refreshToken);
        secureLocalStorage.setItem("accesstoken", loginResult.accessToken);
        secureLocalStorage.setItem("role", loginResult.Role);
        secureLocalStorage.setItem("email", loginResult.Email);
        
        //체크 (로그인 시도 계정이 현재 로그인되어 있는치 체크. 다른곳에서 로그인되어 있으면 IP등 정보를 리턴)
        let checkResult = await agmsClientRequestGet(`/api/staff/home/check?device=WEB`);

        //현재 로그인한 개수? 이거에 대한 무언가를 처리해야할까?
        if(checkResult.currentWebLoginCount > 0){
          
          if(checkResult.loginOtherIp === false){

            //컨펌 (DB에 refresh 토큰을 저장한다.)
            let confirmResult = await agmsClientConfirmGet(`/api/staff/home/confirm?device=WEB`);
            secureLocalStorage.setItem("uuid", confirmResult.tokenUuid);
          }
          else{
            if(
              window.confirm(
                "현재 다른 IP에서 로그인이 되어있습니다.\n기존 로그인을 로그아웃 후 새로 로그인을 하시겠습니까?"
              )
            )
            {
              //컨펌 (DB에 refresh 토큰을 저장한다.)
              let confirmResult = await agmsClientConfirmGet(`/api/staff/home/confirm?device=WEB`);
              secureLocalStorage.setItem("uuid", confirmResult.tokenUuid);
            }
            else{
              let result = await agmsClientRequestGet(
                `/api/logout`
              );
              secureLocalStorage.removeItem("refreshtoken");
              secureLocalStorage.removeItem("accesstoken");
              secureLocalStorage.removeItem('authUser');
              
              history.push('/');
            }
          }
        }else{
          //컨펌 (DB에 refresh 토큰을 저장한다.)
          let confirmResult = await agmsClientConfirmGet(`/api/staff/home/confirm?device=WEB`);
          secureLocalStorage.setItem("uuid", confirmResult.tokenUuid);
        }

        history.push("/patient/list", {
          isAfterLogin: true,
        });
      }

    } 
    catch (error) {
      if (error.response?.status === 416) {
        alert("비밀번호 찾기를 시도해주세요.");
      } else if (error.response?.status === 412) {
        alert("메일 인증시간이 초과 되었습니다. 다시 회원가입을 해주세요.");
      } else if (error.response?.status === 410) {
        alert("회원 인증 기간이 만료되었습니다.\n계정 메일로 보낸 재 인증 링크를 클릭하여 기간 연장 후 사용해 주세요.");
      } else if (error.response?.status === 401) {
        alert("로그인 정보를 확인해주세요.");
      } else {
        alert("로그인을 할 수 없습니다.");
      }
    }
  };

  const handleHospitalChange = (event) => {
    setHospitalId(event.target.value);
  }

   //function : 비밀번호 찾기 버튼 클릭 이벤트 함수
  const onChangePassword = (e) => {
    const passwordRegex =
      /^(?=.*[a-zA-Z])(?=.*[!@%$%^&*()+=._-])(?=.*[0-9]).{8,35}$/;
    const passwordCurrent = e.target.value;
    setLoginPassword(passwordCurrent);
    if (!passwordRegex.test(passwordCurrent)) {
      setIsPassword(false);
    } else {
      setIsPassword(true);
    }
    if (passwordCurrent.length === 0) {
      setIsPassword(false);
    }
  };

  const onChangePasswordSignUp = (e) => {
    const passwordSignUpRegex =
      /^(?=.*[a-zA-Z])(?=.*[!@%$%^&*()+=._-])(?=.*[0-9]).{8,35}$/;
    const passwordSignUpCurrent = e.target.value;
    setPassword(passwordSignUpCurrent);
    if (!passwordSignUpRegex.test(passwordSignUpCurrent)) {
      setIsPasswordSignUp(false);
    } else {
      setIsPasswordSignUp(true);
    }
    if (passwordSignUpCurrent.length === 0) {
      setIsPasswordSignUp(false);
    }
  };

  const handleUserPhonePress = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setUserCall(e.target.value);
    }
  };

  //아이디 기억하기 useEffect
  useEffect(() => {
    if (rememberID) {
      secureLocalStorage.setItem("localEmail", loginId);
    } 
    else if (!rememberID) {
      secureLocalStorage.removeItem("localEmail");
    }
  }, [rememberID]);

  useEffect(() => {
    if (userCall.length === 10) {
      setUserCall(userCall.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    }
    if (userCall.length === 13) {
      setUserCall(
        userCall.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
    }
  }, [userCall]);

  const handleHosPhonePress = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setHosCall(e.target.value);
    }
  };

  // useEffect 함수 - 2번째 인자의 값이 변하면 함수의 내용이 실행된다.
  useEffect(() => {
    if (hosCall.length === 10) {
      setHosCall(hosCall.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    }
    if (hosCall.length === 13) {
      setHosCall(
        hosCall.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
    }
  }, [hosCall]);

  const handleBirthPress = (e) => {
    const regex = /^[0-9\b -]{0,10}$/;
    if (regex.test(e.target.value)) {
      setBirthday(e.target.value);
    }
  };

  useEffect(() => {
    if (birthday.length === 8) {
      setBirthday(birthday.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3"));
    }
    if (birthday.length === 10) {
      setBirthday(
        birthday.replace(/-/g, "").replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3")
      );
    }
  }, [birthday]);

  useEffect(() => {
    if (localEmail) {
      setLoginId(localEmail);
      setRememberID(true);
    }
  }, [localEmail]);

  // useEffect(() => {
  //   // let parse = JSON.parse(autoLogin);
  //   // if (parse) {
  //   //   setLoginId(parse.id);
  //   //   setLoginPassword(parse.pw);
  //   //   setIsPassword(true);
  //   //   if (parse.auto) {
  //   //     clickAutoSignIn();
  //   //   }
  //   // }
  //   // if (autoLogin) {
  //   //   secureLocalStorage.setItem("autoLogin", autoLogin);
  //   // }

  // }, [autoLoginCheck]);


  //병원 리스트 가져오기
  const callHospitalApi = async () => {
    let hospitalListResult = await agmsClientRequestGet('/api/hospitals');
    setHospitalList(hospitalListResult);
  }

  //새로고침 시에 input에 focus주기
  const emailFieldRef = useRef(null);
  const passwordFieldRef = useRef(null);
  const buttonRef= useRef(null);
 
  //mount UseEffect
  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.focus();
    }

    setTermAllAgreeInfo({
     agree: false
    });

    //병원 리스트 가져오기
    callHospitalApi();

    //아이디 기억하기 체크
    //로컬에 저장된localEmail이 있으면    
    if(secureLocalStorage.getItem('localEmail') !== null){
      //체크 표시해주고
      setRememberID(true);
      //아이디 입력창에 기억된 아이디 넣어주기
      setLoginId(secureLocalStorage.getItem('localEmail'));
    }

    //자동 로그인 체크
    //로컬에 저장된 autoLogin이 있으면
    if(secureLocalStorage.getItem('autoLogin') !== null){
      //체크 표시
      setAutoLoginCheck(true);
    }else{
     
    }
  },[]);

  const onChangeAcceptCode = (e) => {
    const AcceptCodeRegex =
      /^(?=.*[a-zA-Z])(?=.*[!@%$%^&*()+=._-])(?=.*[0-9]).{8,35}$/;
    const AcceptCodeCurrent = e.target.value;
    setAcceptCode(AcceptCodeCurrent);
    if (!AcceptCodeRegex.test(AcceptCodeCurrent)) {
      setAcceptCodeOk(false);
    } else {
      setAcceptCodeOk(true);
    }
    if (AcceptCodeCurrent.length === 0) {
      setAcceptCodeOk(false);
    }
  };

  // 약관동의 체크박스 관련 - add 2023-04-26, ykw
  // const [allCheck, setAllCheck] = useState(false);

  // const allBtnEvent =()=>{
  //   if(allCheck === false) {
  //     setAllCheck(true);
  //   }else {
  //     setAllCheck(false);
  //   } 
  // };

  //로그인 화면 전환 시 이용 약관 동의를 다시 해야함
  const setLogInUpState =(setValue)=>{
    if(setValue === true){
      setLogInUp(true);
      setTermAllAgreeInfo({ agree: "false" });//이용 약관 동의여부 false

    }
    else{
      setLogInUp(false);
      setTermAllAgreeInfo({ agree: "false" });//이용 약관 동의여부 false
    }
  }

  const hospitalMenuItemList = hospitalList.map((item, index) => {
    return <MenuItem key={index} value={item.hospital_id}>{item.hospital_name}</MenuItem>;
  });

  return (
    <React.Fragment>
      <div
        className="row m-0 p-0"
        style={{ display: "flex", justifyContent: "end" }}
      >
        <div
          className="col-lg-8 d-none d-lg-block m-0 p-0"
          style={{ position: "fixed", left: 0 ,}}
        >
          <img src={UXNBackgroundImage} style={{ width: "100%", height: "100vh" }} />
        </div>
        

        <div
          className="col-lg-4 d-flex flex-column align-items-center justify-content-center m-0"
          style={{
            minHeight: "100vh",
            padding: "0px 20px",
            backgroundColor:'#ffffff'
          }}
        >
          {logInUp ? (
            <form
              className="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
              onSubmit={clickSignUp}
            >
              <div style={{marginTop:'80px', marginBottom:'50px', fontWeight:'bold', fontSize:'40px'}}>
                회원 가입
              </div>
              
              <div
                style={{
                  width: "100%",
                  lineHeight: "1",
                  textAlign: "left",
                  marginBottom: "20px",
                  
                }}
              >
                <span
                  style={{
                    fontWeight: "normal",
                    fontSize: "17px",
                    cursor: "default",
                    marginRight:'20px'
                  }}
                >
                  이미 계정이 있으신가요?
                </span>
                <span
                  style={{
                    fontWeight: "normal",
                    fontSize: "17px",
                    color: "#5E2589",
                    cursor: "pointer",
                  }}
                  onClick={() => setLogInUp(false)}
                >
                  <b>로그인 하기</b>
                </span>
              </div>

              <div style={{ width: "100%" }}>
                {/* <TextField 
                    fullWidth 
                    label="의료기관 이름" 
                    value={hosName}
                    onChange={(e) => setHosName(e.target.value)}/> */}
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">의료기관 이름</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={hospitalId}
                      label="의료기관 이름"
                      onChange={handleHospitalChange}
                    >
                      {hospitalMenuItemList}
                      {/* <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem> */}
                    </Select>
                  </FormControl>

                <div style={{height:"25px"}}></div>
                
                <TextField 
                    fullWidth 
                    label="이름" 
                    value={name}
                    onChange={(e) => setName(e.target.value)}/>

                <div style={{height:"25px"}}></div>
                
                <TextField 
                    fullWidth 
                    label="전화번호" 
                    value={userCall}
                    onChange={handleUserPhonePress}/>

                <div style={{height:"25px"}}></div>

                <TextField 
                    fullWidth 
                    type='email'
                    label="이메일" 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}/>
                
                <div style={{height:"25px"}}></div>
                
                <TextField 
                    fullWidth 
                    type="password"
                    label="비밀번호" 
                    value={password}
                    onChange={onChangePasswordSignUp}/>

                <div>
                  영문자, 숫자, 특수문자를 포함한 8~35자 비밀번호를 입력해주세요.
                </div>
                <div>
                  허용된 특수문자 :!@%$%^&*()+=._-
                </div>

                <div style={{height:"15px"}}></div>
                
                <TextField 
                    fullWidth 
                    type="password"
                    label="비밀번호 확인"
                    value={passwordCheck} 
                    onChange={(e) => setPasswordCheck(e.target.value)}/>
              </div>
              <div               
              style={{
                width: "100%",
                borderRadius: "6px",
                padding: "13px 16px",
              }}>
              <div>
                {/* <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "6px",
                  }}
                >
                  <input
                    type="checkbox"
                    style={{
                      width: "18px",
                      height: "19px",
                      marginRight: "10px",
                    }}
                    checked={allCheck} onChange={allBtnEvent}
                  />
                  <span>전체동의</span>
                </div> */}
                <div>
                <span
                  style={{
                    fontWeight: "normal",
                    //fontSize: "17px",
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={() => setTermAgreement(!termAgreement)}
                >
                  <Checkbox 
                    checked={termAllAgreeInfo.agree}
                  />
                  <u>클릭하여 이용 약관 및 개인정보 이용 동의 필요</u>
                </span>
              </div>
                
              </div>
              </div>  
              <div style={{ width: "100%", marginBottom: "20px" }}>
                <button
                  style={{
                    width: "100%",
                    backgroundColor: "#5E2589",
                    color: "#fff",
                    fontSize: "17px",
                    fontWeight: "600",
                    border: "none",
                    borderRadius: "4px",
                    padding: "16px 0px",
                  }}
                  onClick={clickSignUp}
                >
                  등록
                </button>
              </div>
            </form>
          ) : (
            <form
              className="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
              onSubmit={clickSignIn}
            >
              <div
                style={{
                  width: "100%",
                  fontWeight: "bold",
                  fontSize: "38px",
                  lineHeight: "1",
                  textAlign: "left",
                  marginBottom: "52px",
                  textAlign: "center",
                }}
              >
                로그인
              </div>
              
              <div style={{ width: "80%", maxWidth:'400px' }}>                
                <TextField 
                  fullWidth 
                  type="email" 
                  //label="이메일 주소" 
                  className="loginInput loginEmailInput" 
                  value={loginId}
                  placeholder="이메일 주소"
                  //required
                  inputRef={emailFieldRef}
                  onChange={(e) => setLoginId(e.target.value)}/>

                <div style={{height:"25px"}}></div>
                <TextField 
                    fullWidth 
                    type="password" 
                    placeholder="비밀번호"
                    className="loginInputPw" 
                    value={loginPassword}
                    //required
                    inputRef={passwordFieldRef}
                    onChange={onChangePassword}
                    />
               
              </div>
              <div
                style={{
                  width: "80%",
                  padding: "6px 0px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  backgroundColor:'#ffffff',
                  maxWidth:'400px'
                }}
              >
                {/* <div
                  style={{
                    fontSize: "14px",
                    lineHeight: 1,
                    color: "#56C79C",
                  }}
                >
                  영문자, 숫자, 특수문자를 포함한 8~35자 비밀번호를
                  입력해주세요.
                  <br />
                  허용된 특수문자 :!@%$%^&*()+=._-
                </div> */}
              </div>
              <div style={{ width: "80%", maxWidth:'400px',marginBottom: "33px" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "6px",
                  }}
                >
                  <input
                    type="checkbox"
                    style={{
                      width: "18px",
                      height: "19px",
                      marginRight: "10px",
                    }}
                    checked={rememberID}
                    onChange= {() => handleRememberIdClick()}
                    // {() => setRememberID(!rememberID)}
                  />
                  <span>아이디 기억하기</span>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    style={{
                      width: "18px",
                      height: "19px",
                      marginRight: "10px",
                    }}
                    checked={autoLoginCheck}
                    onChange={() => setAutoLoginCheck(!autoLoginCheck)}
                  />
                  <span>자동 로그인</span>
                </div>
              </div>
              <div style={{ width: "80%", maxWidth:'400px',marginBottom: "20px" }}>
                <button
                  style={{
                    width: "100%",
                    backgroundColor: "#5E2589",
                    color: "#fff",
                    fontSize: "17px",
                    fontWeight: "600",
                    border: "none",
                    borderRadius: "4px",
                    padding: "16px 0px",
                    
                  }}
                  // onClick={clickSignIn}
                >
                  로그인
                </button>
              </div>

              <div style={{ width: "80%", maxWidth:'400px',marginBottom: "20px" }}>
                <button
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    color: "black",
                    fontSize: "17px",
                    fontWeight: "600",
                    border: "1px solid black",
                    borderRadius: "4px",
                    padding: "12px 0px",
                    
                  }}
                  onClick={onClickGoogleLogin}
                >
                  <img src={GoogleFontImage} alt="GoogleLogo" style={{height:'25px', marginRight:'10px'}}/>구글로 로그인
                </button>
              </div>

              <div style={{ width: "100%", maxWidth:'400px',textAlign: "center" }}>
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={() => setLogInUp(true)}
                >
                  회원가입
                </span>
                {/* <span
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "#56C79C",
                    cursor: "default",
                    margin: "0px 13.5px",
                  }}
                >
                  |
                </span>
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "#56C79C",
                    cursor: "pointer",
                  }}
                  onClick={() => setChangePW(!changePW)}
                >
                  아이디 찾기
                </span> */}
                <span
                  style={{
                    
                    color: "#56C79C",
                    cursor: "default",
                    margin: "0px 70px",
                  }}
                >
                  
                </span>
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={() => setFindId(!findId)}
                >
                  비밀번호 찾기
                </span>
              </div>
            </form>
            
            
          )}
        </div>
      </div>
      {termAgreement ? <TermAgreement termAgreement={termAgreement} setTermAgreement={setTermAgreement} /> : null}
      {findId ? <FindIdModal findId={findId} setFindId={setFindId} /> : null}
      {changePW ? (
        <ChangePWModal changePW={changePW} setChangePW={setChangePW} />
      ) : null}
    </React.Fragment>
  );
};
export default withRouter(Login);

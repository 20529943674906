import axios from "axios";
import secureLocalStorage from "react-secure-storage";
//API 주소 입니다 supUrl = 테스트 서버 prod = 운영서버 입니다.
// 13 번째 줄에 적용해주시면 됩니다.
const localUrl = "http://localhost:8080";//Local 서버
// const comurl = "https://52.78.20.218:8080";//UXN 서버
const comurl = "https://agmsview.kr:8080";//UXN 서버
const uxnUrl = "http://118.131.46.69:8080";

// const comurl = "http://3.34.218.91:8080";
// const host = window.location.host;
// const origin = `${window.location.origin}:8088`;

axios.defaults.withCredentials = true;
export const apiUrl = comurl;//comurl;//localUrl;//uxnUrl;
// export const apiUrl = host === "localhsost:3000" ? subUrl : origin;

export const setToken = (token) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const form = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "multipart/form-data",
    withCredentials: true,
  },
});

export const client = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    withCredentials: true,
  },
});

export const update = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
    withCredentials: true,
  },
});

export const updates = axios.create({
  // baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
    withCredentials: true,
  },
});

export const loginPost = async (url, body) => {
  const res = await update.post(url, body, {
    withCredentials: true,
  });
  if (res.status >= 200 && res.status <= 299) {
    return res.data;
  } 
  else {
    let message;
    if (res.status) {
      switch (res.status) {
        // case 404:
        //   message = "Sorry! the page you are looking for could not be found";
        //   break;
        // case 500:
        //   message =
        //     "Sorry! something went wrong, please contact our support team";
        //   break;
        // case 401:
        //   message = "Invalid credentials";
        //   break;
        default:
          message = res.message;
          break;
      }
    }
    return message;
  }
};

export const agmsClientLoginPost = async (url, body) =>{
  const res = await update.post(url, body, {
    withCredentials: true,
  });
  if (res.status >= 200 && res.status <= 299) {
    return res.data;
  } 
  else {
    let message;
    if (res.status) {
      switch (res.status) {
        // case 404:
        //   message = "Sorry! the page you are looking for could not be found";
        //   break;
        // case 500:
        //   message =
        //     "Sorry! something went wrong, please contact our support team";
        //   break;
        // case 401:
        //   message = "Invalid credentials";
        //   break;
        default:
          message = res.message;
          break;
      }
    }
    return message;
  }
}

export const agmsClientConfirmGet = async (url, body) => {
  const accessToken = secureLocalStorage.getItem("accesstoken");
  const refreshToken = secureLocalStorage.getItem("refreshtoken");
  
  //const UUID = localStorage.getItem("uuid");
  let headers = {};
  if (accessToken) {
    headers = {
      Authorization: `Bearer ${accessToken}`,
      withCredentials: true,
      refresh_token: `jwt ${refreshToken}`
      //uuid: `${UUID}`,
    };
  }

  // const res = await client.get(url, { headers });
  const res = await client.get(url, { headers });
 
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
   
    let message;
    if (res.status) {
      switch (res.status) {
        default:
          message = res.message;
          break;
      }
    }
    return message;
  }
}

export const testPost = async (url, body) => {
  try {
    const res = await updates.post(url, body, {
      withCredentials: true,
    });
    if (res.status >= 200 && res.status <= 299) {
      return res.data;
    } else {
      let message;
      if (res.status) {
        switch (res.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = res.message;
            break;
        }
      }
      alert(message);
    }
  } catch (error) {
    // alert("아이디와 비밀번호를 확인해주세요");
  }
};

export const requestPost = async (url, body) => {
  const token = secureLocalStorage.getItem("refreshtoken");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
      withCredentials: true,
    };
  }
  // const res = await client.post(url, body, { headers });
  const res = await update.post(url, body, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 409:
          message = "요청이 존재합니다.";
          break;
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message = res.message;
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    return message;
  }
  
};



export const formPost = async (url, body) => {
  const token = secureLocalStorage.getItem("authUser");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
      withCredentials: true,
    };
  }
  // const res = await client.post(url, body, { headers });
  const res = await form.post(url, body, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 409:
          message = "요청이 존재합니다.";
          break;
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message = "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = "";
          break;
      }
    }
    return alert(message);
  }
};
export const adminRequestPost = async (url, body) => {
  const token = secureLocalStorage.getItem("authUser");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
      withCredentials: true,
    };
  }
  const res = await update.post(url, body, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};
export const requestPut = async (url) => {
  const token = secureLocalStorage.getItem("authUser");
  let headers = {};
  if (token) {
    headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      withCredentials: true,
    };
  }
  const res = await client.put(url, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};
export const adminUserPut = async (url, body) => {
  const token = secureLocalStorage.getItem("authUser");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
      withCredentials: true,
    };
  }
  const res = await update.put(url, body, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};
export const requestGet = async (url) => {
  const token = secureLocalStorage.getItem("refreshtoken");
  const UUID = secureLocalStorage.getItem("uuid");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
      withCredentials: true,
      uuid: `${UUID}`,
    };
  }

  // const res = await client.get(url, { headers });
  const res = await client.get(url, { headers });

  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        default:
          message = res.message;
          break;
      }
    }
    return message;
  }
};
export const agmsClientRequestGet = async(url) => {
  const token  = secureLocalStorage.getItem("accesstoken");
  const UUID = secureLocalStorage.getItem("uuid");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
      withCredentials: true,
      uuid: `${UUID}`,
    };
  }
 
  try{
    const res = await client.get(url, { headers });
    if (res.status >= 200 || res.status <= 299) {
      return res.data;
    } 
    else 
    {
      let message;
      if (res.status) {
        switch (res.status) {
          default:
            message = res.message;
            break;
        }
      }
      return message;
    }
   
  }
  catch(error){
    //로그아웃 된 상태에서는 토큰 관련 이슈 스킵
    if(secureLocalStorage.getItem('authUser') !== null){
      //access토큰이 만료되었다면?
      console.log('agmsClientRequestGet에서 에러 : ' + error);
      console.log(error.message.substr(-3));
      if(error.message.substr(-3) === '401'){
        try{
          const renew_token = secureLocalStorage.getItem("refreshtoken");
          const body = {
            refresh_token : renew_token
          }
          
          if (renew_token) {
            headers = {
              Authorization: `Bearer ${renew_token}`,
              withCredentials: true,
            };
          }
          // const res = await client.post(url, body, { headers });
          const res = await update.post(`/api/staff/token`, body, { headers });
          if (res.status >= 200 || res.status <= 299) {
            secureLocalStorage.setItem("accesstoken", res.data.access_token);
            
            let data = await agmsClientRequestGet(url);
            console.log('accesstoken 갱신 완료');
            return data;
          } 
          else 
          {
            let message;
            if (res.status) {
              switch (res.status) {
                case 409:
                  message = "요청이 존재합니다.";
                  break;
                case 404:
                  message = "Sorry! the page you are looking for could not be found";
                  break;
                case 500:
                  message = res.message;
                  break;
                case 401:
                  message = "Invalid credentials";
                  break;
                default:
                  message = res.message;
                  break;
              }
            }
            return message;
          }
        }
        catch(error){
          //리프레시 토큰 갱신
          console.log('refreshToken 에러 : ' + error);
          console.log(error.message.substr(-3));
          //로그아웃으로 유도
          if(error.message.substr(-3) === '401' 
          || error.message.substr(-3) === '433' 
          || error.message.substr(-3) === '434'){

            //에러 메시지 공시
            if(error.message.substr(-3) === '401'){
              //한번만 발동하도록 설정
              if(secureLocalStorage.getItem("logoutTrigger") === false){
                secureLocalStorage.setItem("logoutTrigger", true);
                alert('로그인이 만료되었습니다. 다시 로그인 해주세요!');
                if(apiUrl === "https://agmsview.kr:8080"){
                  window.location.href = "https://agmsview.kr/login";
                  window.close();
                }
                else if(apiUrl === "http://118.131.46.69:8080")
                {
                  window.location.href = "http://118.131.46.69/login";
                  window.close();
                }
              }
            }
            else if(error.message.substr(-3) === '433'){
              //한번만 발동하도록 설정
              if(secureLocalStorage.getItem("logoutTrigger") === false){
                secureLocalStorage.setItem("logoutTrigger", true);
                alert('다른 IP에서 로그인이 되었습니다. 로그아웃 합니다.\n본인이 아닐 경우 비밀번호를 변경 해주세요.');
                if(apiUrl === "https://agmsview.kr:8080"){
                  window.location.href = "https://agmsview.kr/login";
                  window.close();
                }
                else if(apiUrl === "http://118.131.46.69:8080")
                {
                  window.location.href = "http://118.131.46.69/login";
                  window.close();
                }
              } 
            }
            else if(error.message.substr(-3) === '434'){
              //한번만 발동하도록 설정
              if(secureLocalStorage.getItem("logoutTrigger") === false){
                secureLocalStorage.setItem("logoutTrigger", true);
                alert('현재 계정에서 로그아웃이 발생했습니다.\n본인이 아닐 경우 비밀번호를 변경 해주세요.');
                console.log(error.message);
                // if(apiUrl === "https://agmsview.kr:8080"){
                //   window.location.href = "https://agmsview.kr/login";
                //   window.close();
                // }
                // else if(apiUrl === "http://118.131.46.69:8080")
                // {
                //   window.location.href = "http://118.131.46.69/login";
                //   window.close();
                // }
              }
            }

            return;
          }
        }
      }
    } 
  }
};

export const agmsClientRequestPost = async (url, body) => {
  const token = secureLocalStorage.getItem("accesstoken");
  
  let headers = {};
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      withCredentials: true,
    };
  }
  // const res = await client.post(url, body, { headers });


  // try{
  //   

  // }
  // catch(error){

  // }
  const res = await update.post(url, body, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 409:
          message = "요청이 존재합니다.";
          break;
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message = res.message;
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    return message;
  }
};

export const agmsClientRequestPatch = async (url, body) => {
  const token = secureLocalStorage.getItem("accesstoken");
  
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
      withCredentials: true,
    };
  }
  const res = await update.patch(url, body, { headers });

  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        default:
          message = res.message;
          break;
      }
    }
    return message;
  }
};

// export const loginGet = async (url, token, uuId) => {
//   let headers = {};
//   if (token && uuId) {
//     headers = {
//       Authorization: `Bearer ${token}`,
//       withCredentials: true,
//       uuid: uuId,
//     };
//   } else if (token && !uuId) {
//     headers = {
//       Authorization: `Bearer ${token}`,
//       withCredentials: true,
//     };
//   }
//   // const res = await client.get(url, { headers });
//   const res = await client.get(url, { headers });
//   if (res.status >= 200 || res.status <= 299) {
//     return res.data;
//   } else {
//     let message;
//     if (res.status) {
//       switch (res.status) {
//         default:
//           message = res.message;
//           break;
//       }
//     }
//     return message;
//   }
// };

export const requestDelete = async (url) => {
  const token = secureLocalStorage.getItem("authUser");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
      withCredentials: true,
    };
  }
  const res = await client.delete(url, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};

export const imgPut = async (url, body) => {
  const token = secureLocalStorage.getItem("authUser");
  let headers = {};
  if (token) {
    headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
      withCredentials: true,
    };
  }
  const res = await client.put(url, body, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};

export const requestPatch = async (url, body) => {
  const token = secureLocalStorage.getItem("authUser");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
      withCredentials: true,
    };
  }
  const res = await update.patch(url, body, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        default:
          message = res.message;
          break;
      }
    }
    return message;
  }
};
